@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  flex-wrap: false;
  align-items: center;
  //width: 100%
  margin: 0 0rem;
  //border: 1px solid white
  border-radius: 20px;
  height: 2rem;
  margin-top: 9px;
  background-color: white;
  min-width: 360px; }

.auto {
  width: max-content; }


.btn {
  background: transparent;
  border: 0; }

.searchIcon {
  color:#d5faab {}
  align-content: center; }

@media screen and (max-width: $tablet) {
  .outbox {
    display: flex;
    align-items: center;
    //border: 1px solid white
    border-radius: 20px;
    height: 2rem;
    background-color: white;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 4px; }


  .auto {
    width: max-content; }


  .btn {
    background: transparent;
    border: 0; } }


