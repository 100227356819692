@import '../../../modules/mediaQuery/app';

@media screen and (max-width: $tablet) {
  .outbox {
    width: 100%;
    min-height: max-content;
    padding: 0 2rem; }

  .box {
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: black;
    background: rgba(125,125,125,.2);
    border-radius: 8px;
    flex-wrap: wrap; }
  .content {
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: .8rem;
    cursor: pointer;
    padding: 2px .5rem;
    white-space: nowrap; }

  .selected {
    border-bottom: 1px solid blue; } }

@media screen and (max-width: $mobileL) {
  .content {
    font-size: .5rem;
    height: 1rem; } }


