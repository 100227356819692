.nav-link {
  padding: 0;
  color: white;
}

.dropdown-menu {
  padding: 0px 0px;
}

#nav-dropdown{
  background-color: #5679ec;
}

#nav-dropdown-item{
  color: white;
  background-color: #5679ec;
}

#nav-dropdown-item:hover{
  color: #0056b3;
 
}