@import '../../../modules/mediaQuery/app';

.outbox {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  background: #5679ec; }

.content {
  display: flex;
  height: 8vh; }

.left {
  display: flex; }

.logo {
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #12329b;
  color: white;
  margin: 0 1rem;
  font-size: 1.5rem;
  &:hover, &:focus {
    cursor: pointer; } }

.mid {
  display: flex;
  margin: 0 0rem; }


.right {
  display: flex;
  font-size: 1.1rem;
  color: white !important;
  margin: auto;
  align-items: left; }


@media screen and (max-width: $tablet) {
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .logo {
    margin-left: 1rem;
    margin-right: auto;
    font-size: 1.3rem; }

  .toggle_top {
    height: 10px; }

  .toggle_outbox {
    display: flex;
    width: 3rem;
    flex-wrap: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

  .toggle_content {
    height: 100vh;
    width: 100vw;
    background: #5679ec;
    color: black;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    overflow: scroll; }

  .accordion_header_style {
    background: rgb(125,125,125); }

  .accordion_style {
    background: #5679ec; }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px; }

  .down {
    transform: rotate(45deg) translate(-4px, 0px);
    -webkit-transform: rotate(45deg) translate(-4px, 0px); }

  .up {
    transform: rotate(-135deg) translate(0px, -2px);
    -webkit-transform: rotate(-135deg) translate(0px, -2px); }

  .toggle_icon {
    height: max-content;
    margin: .6rem;
    margin-left: auto;
    margin-right: 1rem;
    &:hover, &:blur {
      .bar1 {
        transform: rotate(-45deg) translate(-9px, 6px); }
      .bar2 {
        opacity: 0; }
      .bar3 {
        transform: rotate(45deg) translate(-8px, -8px); } } }

  .bar1, .bar2, .bar3 {
    width: 2rem;
    height: 0.3rem;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s; }

  .side_title {
    width: 100%;
    background: rgb(125,125,125);
    font-size: 1rem;
    padding: .2rem .2rem .2rem .5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap; }

  .side_title_close {
    font-size: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
    &:hover {
      cursor: pointer; } }

  .side_content {
    padding: 0.8rem;
    font-size: .8rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,.5);
    &:hover {
      cursor: pointer;
      color: #0056b3; } }

  #toggle {
    background-color: #FFE600;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll; }

  .hide {
    transform: translate3d(700vw, 0, 0);
    transition: transform 1s linear; }

  .show {
    transform: translate3d(0vw, 0, 0);
    transition: transform .3s linear;
    + .toggle_icon {
      display: none; } } }

