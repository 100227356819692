@import '../../../modules/mediaQuery/app';

@media screen and (max-width: $tablet) {
  .sub_title {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2px 0;
    &:hover, &:focus {
      border-bottom: 1px solid blue;
      cursor: pointer; } }

  .selected {
    border-bottom: 1px solid blue; }

  .title {
    font-size: .8rem;
    padding: 0 .5rem; } }

@media screen and (max-width: $mobileL) {
  .title {
    font-size: .5rem; } }
