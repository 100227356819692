@import '../../../modules/mediaQuery/app';

.outbox {
  min-width: 370px;
  min-height: 568px;
  max-width: 100vw;
  max-height: 100vh; }

.box {
  display: flex;
  //justify-content: space-around
  margin-top: 8vh;
  max-width: 100%; }

.filter {
  margin: 1rem; }

.products {
  display: flex;
  width: 95%;
  flexWrap: wrap !important; }

.product {
  min-width: 260px;
  max-width: 280px;
  text-align: center;
  margin: .5rem; }

@media screen and (max-width: $tablet) {
  .box {
    display: flex;
    flex-direction: column;
    //justify-content: space-around
    //align-items: center
 }    //margin-top: 8vh

  .filter {
    margin: 0;
 }    //width: 100%

  .products {
    width: 100%; }

  .product {
    min-width: 170px;
    //max-width: 200px
    text-align: center;
 } }    //margin: .5rem
