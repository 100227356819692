
.outbox {
  display: flex;
  align-items: center;
  margin: .1rem 0;
  label {
    margin: 0;
    display: flex;
    &:hover {
      cursor: pointer; } }
  input {
    opacity: 0;
    display: none;
    &:checked+label {
      .checkbox {
        // background: blue
        width: 16px;
        height: 8px;
        border-width: 0 0 2px 2px;
        opacity: 1;
        border-style: solid;
        border-color: blue;
        transform: rotateZ(-50deg) scale(1);
        animation: check .2s ease-out; } } } }
@keyframes check {
  0% {
    opacity: 1;
    transform: rotateZ(-50deg) scale(0); }
  100% {
    opacity: 1;
    transform: rotateZ(-50deg) scale(1.4); } }

.checkbox {
  width: .8rem;
  height: .8rem;
  border: 2px solid #ffc107;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  margin-top: .4rem; }


