.outbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: max-content;
  z-index: 3;
  position: relative;


  input {
    //border: 1px solid white
    //border-radius: 15px
    min-width: 320px;
    border: none;
    outline: none;
    background: none;
    //border-bottom: 1px solid black
    //box-shadow: none
    padding: 0;
    padding-left: .8rem;
    height: 2rem;
    //caret-color: #FFD300
    cursor: text;
 }    //color: #FFD300
  input::placeholder {
    //padding-left: .5rem
    color:  rgb(179, 179, 179) !important; } }
.sugges_outbox {
  border: 1px solid grey;
  min-width: min-content;
  background: white;
  position: absolute;
  top: 2rem; }
.sugges_box {}

.sugges_category {
  padding-left: 1rem;
  font-size: 1.1rem;
  color: white;
  background: rgb(141, 232, 219); }
.sugges_arr {
  display: flex;
  flex-direction: column; }
.sugges_value {
  font-size: 1rem;
  color: grey;
  padding: 0 2rem;
  white-space: nowrap;
  width: min-content;
  min-width: 100%;
  &:hover {
    background: rgb(141, 232, 219);
    width: 100%;
    cursor: pointer; } }



