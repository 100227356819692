@import '../../../modules/mediaQuery/app';

.outbox {
  min-width: 370px;
  min-height: 568px;
  max-width: 100vw;
  max-height: 100vh; }

.departments {
  display: flex;
  justify-content: space-around;
  margin-top: 8vh;
  max-width: 85%; }

.showgrid {
  border: 1px solid red; }

.deparmentlinks {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: white; }

.header1 {
  font-size: 2.5rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: white; }

.title {
  font-size: 1.5rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
 }  //background: white

.title_border {
  border-bottom: 1px solid green;
  padding-bottom: .5rem; }

.box {
  display: flex;
  justify-content: space-around;
  max-width: 100%; }

.topBackground {
  position: absolute;
  top: 60px;
  background-image: url('../../../assets/images/background.jpg');
  height: 80%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px); }

.categories {
  position: absolute;
  top: 600px; }

.search {
  position: absolute;
  top: 30%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-40%);
  -webkit-transform: translateX(-50%) translateY(-40%);
  transform: translateX(-50%) translateY(-40%); }

.filter {
  margin: 1rem; }

.products {
  display: flex;
  width: 85%;
  flexWrap: wrap !important; }

.product {
  min-width: 260px;
  max-width: 260px;
  text-align: center;
 }  //margin: .5rem

.department {
  text-align: center;
  //min-width: 260px
  max-width: 260px;
  min-height: 80px;
  max-height: 80px;
 }  //margin: .5rem


@media screen and (max-width: $tablet) {
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 8vh; }

  .filter {
    margin: 0;
    width: 100%; }


  .products {
    width: 100%; } }
