@import '../../../modules/mediaQuery/app';

.outbox {
  width: 200px;
  min-width: 100px;
  background: white;
 }  //color: #FFD300

.box {
  display: flex;
  flex-direction: column; }

.title {
  font-size: 1.5rem;
  background: white; }

.title_border {
  border-bottom: 1px solid green;
  padding-bottom: .5rem; }

.tags {
  display: flex;
  flex-wrap: wrap; }

.tag {
  padding: .2rem;
  background: rgba(0,0,0,.1);
  margin: .2rem;
  display: flex;
  flex-wrap: nowrap; }

.tag_content {
  margin: 0 .2rem; }

.tag_close {
  padding: 0 .2rem;
  border-left: 1px solid white;
  cursor: pointer; }

.content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem; }

.block {
  margin-bottom: 1rem; }

.sub_title {
 }  // font-size: 1rem

.clearLink {
  float: right;
  button {
    color: red;
    font-size: 1rem;
    background: none;
    border: none;
    &:focus {
      outline: none; } } }

.sorting {
  //float: right
  border: 0px solid #000000; }

.btn-link {
  color: black; }

.sortingDropdown {
  width: 100%;
  text-align: left;
  background: none;
  border: 0px solid #000000;
  color: black;
  &:hover, &:active, &:focus, &:blur {
    background: none;
    color: black;
    border: 1px solid #000000; } }

.sortingDropdownItem {
  &:hover {
    background: #dddddd;
    color: black; } }

.handleStyle1 {
  position: 'absolute';
  display: 'inline-block';
  cursor: 'pointer';
  height: 30;
  width: 30;
  backgroundColor: '#2771e2';
  border: '1px solid #052350';
  borderRadius: '50%';
  outline: 'none !important'; }


@media screen and (max-width: $tablet) {
  .outbox {
    width: 100%;
    min-width: 100px;
    background: none;
 }    //color: #FFD300

  .title {
    background: none; } }


